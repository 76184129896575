import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Components
import { FiSearch } from "react-icons/fi";

export const SearchComponent = ({ experiencias, showButton = true, customSearchStyles = null }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleProduct = (e) => {
    navigate("/experience/" + e.pathName);
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  const handleSearching = (e) => {
    if (e.key === "Enter") {
      console.log("do validate: " + e.target.value);
      navigate("/search/" + e.target.value);
    }
  };

  const SearchSuggestions = () => {
    const experiencesFilters = experiencias.filter(
      (f) =>
        f.experienceName.toLowerCase().includes(search.toLowerCase()) ||
        f.experienceDescription.toLowerCase().includes(search.toLowerCase()) ||
        f.ciudad.toLowerCase().includes(search.toLowerCase()) ||
        f.pais.toLowerCase().includes(search.toLowerCase())
    );
    if (experiencesFilters.length > 0 && search !== "")
      return (
        <div className="searchSuggestions">
          {experiencesFilters.map((e) => {
            return (
              <div className="searchSuggestionsResult" onClick={() => handleProduct(e)}>
                <div className="searchSuggestionsImg">
                  <img src={e.photos.split(",")[0]} alt="imageSuggestion" />
                </div>
                <div className="searchSuggestionsDesc">
                  <div>{e.experienceName}</div>
                  <div style={{ fontWeight: "400", fontSize: "12px" }}>
                    {e.ciudad} ({e.pais})
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
  };

  return (
    <>
      <div className="searcherInline" style={customSearchStyles ? customSearchStyles.block : {}}>
        <FiSearch style={customSearchStyles ? customSearchStyles.svg : {}} />{" "}
        <input
          type="text"
          onChange={handleSearch}
          onKeyDown={handleSearching}
          placeholder="Estoy buscando..."
          style={customSearchStyles ? customSearchStyles.input : {}}
        />
        {showButton && <button onClick={() => handleSearching({ key: "Enter", target: { value: search } })}>Buscar</button>}
      </div>
      <SearchSuggestions />
    </>
  );
};
