export const parseExperienceValues = (values, data) => {
  let arrayData = [];
  values.map((val) => {
    if (data && data.length > 0) {
      let experience = data.filter((f) => f.id === val.experience);
      if (experience && experience.length > 0) {
        let photos = experience[0].photos.split(",");
        if (photos && photos.length > 0) {
          arrayData.push({
            experience: (
              <div>
                <img src={photos[0]} width="75px" alt="experience" />
                {experience[0].shortdesc}
              </div>
            ),
            date: val.date,
            value: val.value,
          });
        }
      }
    }
  });
  return arrayData;
};

export function convertirFechaISO(fechaISO) {
  //"024-11-04T21:48:30.284Z" -> "04/11/0024 21:48"
  const fecha = new Date(fechaISO);

  const dia = String(fecha.getDate()).padStart(2, "0");
  const mes = String(fecha.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
  const anio = fecha.getFullYear();
  const horas = String(fecha.getHours()).padStart(2, "0");
  const minutos = String(fecha.getMinutes()).padStart(2, "0");

  return `${dia}/${mes}/${anio} ${horas}:${minutos}`;
}
