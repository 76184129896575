import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Usa sessionStorage si prefieres

import userConfigSlice from "./reducers/userConfigSlice";

// Configuración de redux-persist
const persistConfig = {
  key: "root", // Clave principal para almacenar los datos
  storage, // Mecanismo de almacenamiento (localStorage por defecto)
  whitelist: ["aplicationConfig"], // Solo persistir "aplicationConfig"
  // blacklist: ["otraKey"], // No persistir "otraKey"
};

// Reducer combinado
const rootReducer = combineReducers({
  aplicationConfig: userConfigSlice,
});

// Reducer persistente
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Crear el store con el reducer persistente
export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
