//CONSTANTS
const apiRestUrl = "https://api.civitrip.com/api/";
export const civitripToken = "opDXLdbBtYTCOdvtOamctbkBsHcssCehriFELgFFFYHTCCNRcAsXbdYUkBMdDrLuZQxkwD";

const authToken = () => {
  const token = "tY7Uj5&vDf23(/&jhE3WqVkiioLJ74=)/hf";
  let encrypToken = token.match(/.{1,4}/g);

  const date = new Date();
  let currentMonth = date.getMonth() + 50;
  let currentDay = date.getDate() + 33;

  return (
    encrypToken[2] +
    encrypToken[1] +
    currentMonth +
    encrypToken[8] +
    currentDay * 23 +
    encrypToken[6] +
    currentDay +
    encrypToken[5] +
    encrypToken[3] +
    encrypToken[4]
  ).toString();
};

//API DESIGN
const apiCall = (urlRequest) => {
  const url = apiRestUrl + urlRequest;
  return fetch(url)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return res;
      }
    })
    .catch(function (error) {
      console.error("[API Rest Error]" + error.message);
    });
};

const apiCallPost = (urlRequest, data) => {
  const url = apiRestUrl + urlRequest;
  let headers = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, headers)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return res;
      }
    })
    .catch(function (error) {
      console.error("[API Rest Error]" + error.message);
    });
};

const apiCallTicando = (urlRequest, publicApitoken) => {
  const url = urlRequest;

  let headers = {
    /*method: "POST",*/
    headers: {
      "Content-Type": "application/json",
      "x-provider": "creaticket",
      Authorization: `Bearer ${publicApitoken}`,
    },
    /*body: "",*/
  };

  return fetch(url, headers)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return res;
      }
    })
    .catch(function (error) {
      console.error("[API Rest Error]" + error.message);
    });
};

//CUSTOM CALLS
export const getDataWeb = () => {
  return apiCall("dataWeb");
};

export const getExperienciaCategoria = () => {
  return apiCall("getExperienciaCategoria");
};

export const getAllExperiencies = () => {
  return apiCall("getAllExperiencies");
};

export const postProveedor = (data) => {
  return apiCallPost("addProveedor", data);
};

export const getLogin = (data) => {
  return apiCallPost("login", data);
};

export const getProveedores = () => {
  return apiCallPost("getProveedores", {});
};

export const updateUserState = (data) => {
  return apiCallPost("updateUserState", data);
};

export const getExperienciasCategorias = () => {
  return apiCall("experienciasCategorias");
};

export const updateProveedor = (data) => {
  return apiCallPost("updateProveedor", data);
};

export const updateClient = (data) => {
  return apiCallPost("updateClient", data);
};

export const getWebConfiguration = () => {
  return apiCall("webConfiguration");
};

export const getProveedorConfiguration = (data) => {
  return apiCallPost("proveedorConfiguration", data);
};

export const getExperienceByPath = (data) => {
  return apiCallPost("experienceByPath", data);
};

export const getExperiencesBySearch = (data) => {
  return apiCallPost("experiencesBySearch", data);
};

export const updateExperiences = (data) => {
  return apiCallPost("updateExperiences", data);
};

export const getClientTicandoData = (publicApitoken) => {
  return apiCallTicando("https://app.crea-ticket.com/api_prod/v2/data", publicApitoken);
};

export const getClientTicandoStretch = (publicApitoken, stretch_id) => {
  return apiCallTicando(`https://app.crea-ticket.com/api_prod/v2/stretches/${stretch_id}`, publicApitoken);
};

export const postClient = (data) => {
  return apiCallPost("addClient", data);
};

export const getClientCart = (id, data) => {
  return apiCall(`cart/user/${id}`, data);
};

export const getWishlists = (data) => {
  return apiCallPost("getWishlists", data);
};

export const addWishlist = (data) => {
  return apiCallPost("addWishlist", data);
};

export const removeWishlist = (data) => {
  return apiCallPost("removeWishlist", data);
};

export const getProviderCart = (id, data) => {
  return apiCall(`cart/provider/${id}`, data);
};
