import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//Reducers
import { setUserConfig, setAlert } from "../../../../reducers/userConfigSlice";
//Components
import CardLarge from "../../../../components/cardLarge";
//API
import { getProviderCart } from "../../../../api/requests";

export const Sales = () => {
  const experiencias = useSelector((state) => state.aplicationConfig.proveedorConfig.experiencias);
  const mockSalesIds = [52, 52, 54, 51, 51, 54];

  //TODO providerId mock
  const providerId = useSelector((state) => state.aplicationConfig.userConfig.userData.provider_id);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const res = await getProviderCart(providerId);
      if (res) {
        console.log(res);
        setData(res);
      } else {
        console.log("Error.");
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
      setData([]);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="section">
        <div className="row">
          {data.map((sale, index) => {
            return (
              <div className="col-12">
                <CardLarge key={"exp" + index} e={sale.collaboratorTickets[0]} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
