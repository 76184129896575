import React, { useState, useEffect } from "react";
//Redux
import { setProductActive, setPageActive } from "../reducers/userConfigSlice";
import { useSelector, useDispatch } from "react-redux";
//Components
import Searcher from "../sections/searcher";
import FeaturedList from "../sections/featuredList";
import { Newsletter } from "../components/newsletter";
//Api
import { getDataWeb } from "./../api/requests";

export default function Home() {
  const dispatch = useDispatch();
  const [tipoExperiencias, setTipoExperiencias] = useState([]);
  const [experiencias, setExperiencias] = useState([]);
  const [tipoExperienciaSeleccionada, setTipoExperienciaSeleccionada] = useState("1");

  const getAllDataWeb = async () => {
    await getDataWeb().then((res) => {
      console.log(res);
      setExperiencias(res["allExperiencies"]);
      setTipoExperiencias(res["experienciaCategoria"]);
    });
  };

  useEffect(() => {
    getAllDataWeb();
    dispatch(setPageActive("home"));
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Searcher experiencias={experiencias} tipoExperienciaSeleccionada={tipoExperienciaSeleccionada} />
      <FeaturedList
        experiencias={experiencias}
        tipoExperiencias={tipoExperiencias}
        tipoExperienciaSeleccionada={tipoExperienciaSeleccionada}
        setTipoExperienciaSeleccionada={setTipoExperienciaSeleccionada}
      />
      <Newsletter />
    </>
  );
}
