import React, { useState, useEffect, useRef } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { decode as base64_decode, encode as base64_encode } from "base-64";
//Components
//Styles
import "./styles.css";
import { setCartQuantity } from "../../reducers/userConfigSlice";
import { civitripToken } from "../../api/requests";

export const DisponibilitySmall = ({ handleDisponibilityState }) => {
  const dispatch = useDispatch();
  const experience = useSelector((state) => state.aplicationConfig.productActive);

  const id = useSelector((state) => state.aplicationConfig.userConfig.userData.id);
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState("600px");

  /*console.log("DATA");
  console.log({ name: "Antonio Ruiz", email: "contacto@gmail.com", id: 3000 });
  console.log("ENCODE");
  const encoded = base64_encode(JSON.stringify({ name: "Antonio Ruiz", email: "contacto@gmail.com", id: 3000 }));
  console.log(encoded);
  console.log("DECODE");
  const decoded = base64_decode(encoded);
  console.log(JSON.parse(decoded));*/

  console.log("DisponibilitySmall");
  console.log(experience);
  console.log(experience.experienceId);
  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);

  const handleModal = () => {
    console.log("handleModal");
    setOpenModal(!openModal);
  };

  useEffect(() => {
    const handleMessage = (e) => {
      if (/*e.origin === "http://localhost:3006" || e.origin === "http://localhost:3002" ||*/ e.origin === "https://dev.crea-ticket.com") {
        cartUpdated(e.data);
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //======================================

  const ContentIframe = () => {
    const experienceTicandoPath = () => {
      console.log("experience");
      console.log(experience);
      //https://dev.crea-ticket.com/civitrip/opDXLdbBtYTCOdvtOamctbkBsHcssCehriFELgFFFYHTCCNRcAsXbdYUkBMdDrLuZQxkwD/cs/{stretch_id de la tabla experieces}/{operator_id de la tabla experiences}?user_id={id de usuario}
      const urlBase = "https://dev.crea-ticket.com/civitrip/";
      const calendarParams = "/cs/";
      console.log(urlBase + civitripToken + calendarParams + experience.stretch_id + "/" + experience.operator_id + "?user_id=" + experience.userId);
      return urlBase + civitripToken + calendarParams + experience.stretch_id + "/" + experience.operator_id + "?user_id=" + experience.userId;
      //return "http://localhost:3002/civitrip/mtefbovyxnBmwmfFsgEleFAfEtIlFrNrlrXNgTLeGMQDywIrdMcIRdnVWvHbnUTZMqlsJw/stretch/6?key_a_usar=null";
    };

    return (
      <iframe
        id="iframeDisponibility"
        ref={iframeRef}
        scrolling="no"
        className="embebbedIframe"
        src={experienceTicandoPath()}
        title="Details"
        height={iframeHeight}
      ></iframe>
    );
  };

  // =========== CALLBACK CART
  const cartUpdated = (value) => {
    console.log("Disponiblitiy > cartUpdated");
    console.log(typeof value, value);
    if (typeof value === "string" && JSON.parse(value).type && JSON.parse(value).value) {
      console.log("ES OBJETO EN STRING con type y value ====");
      const event = JSON.parse(value);
      console.log(event.type, event.value);
      if (event.type === "cartItems") {
        console.log("IF");
        dispatch(setCartQuantity(parseInt(event.value)));
        if (parseInt(event.value) > 0) {
          handleDisponibilityState("cart");
        }
      } else if (event.type === "height") {
        // Nothing
      } else {
        console.log("ELSE");
        handleDisponibilityState("calendar");
      }
    }
  };

  return (
    <>
      <ContentIframe />
    </>
  );
};
