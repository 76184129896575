import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userConfig: { logged: false, userData: { id: null } },
  proveedorConfig: {},
  productActive: [],
  pageActive: "home",
  wishlist: [],
  adm: {
    experiences: {
      selected: null,
    },
    alert: 0,
    sectionActive: "null",
  },
  cart: {
    quantity: 0,
  },
};

const userConfigSlice = createSlice({
  name: "aplicationConfig",
  initialState,
  reducers: {
    setUserConfig: (state, action) => {
      state.userConfig = action.payload;
    },
    setUserData: (state, action) => {
      state.userConfig.userData = action.payload;
    },
    setProveedorConfig: (state, action) => {
      state.proveedorConfig = action.payload;
    },
    setPageActive: (state, action) => {
      state.pageActive = action.payload;
    },
    setProductActive: (state, action) => {
      state.productActive = action.payload;
    },
    setWishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    setAdmExperienceSelected: (state, action) => {
      state.adm.experiences.selected = action.payload;
    },
    setAdmSectionActive: (state, action) => {
      state.adm.sectionActive = action.payload;
    },
    setAlert: (state, action) => {
      state.adm.alert = action.payload;
    },
    setCartQuantity: (state, action) => {
      state.cart.quantity = action.payload;
    },
  },
});

export const {
  setUserConfig,
  setProveedorConfig,
  setProductActive,
  setPageActive,
  setWishlist,
  setUserData,
  setAdmExperienceSelected,
  setAlert,
  setAdmSectionActive,
  setCartQuantity,
} = userConfigSlice.actions;
export default userConfigSlice.reducer;
