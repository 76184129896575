import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setAdmSectionActive, setUserConfig, setAdmExperienceSelected, setCartQuantity } from "../../reducers/userConfigSlice";
//Components
import { ModalComponent } from "../../components/modal";
import { FiHeart, FiMenu, FiShoppingCart, FiUser, FiUserPlus } from "react-icons/fi";
import { GoDeviceDesktop, GoOrganization, GoInbox, GoSignOut, GoPerson, GoMilestone, GoCreditCard, GoCalendar } from "react-icons/go";
//Styles
import "./style.css";
import iconFavorite from "./../../assets/icons/favorite.svg";
import iconLocation from "./../../assets/icons/location.svg";
import iconCart from "./../../assets/icons/shopping.svg";
import { SearchComponent } from "../../components/searchComponent";
import Logo from "./../../assets/logos/logo.png";
import { CiShoppingCart, CiUser } from "react-icons/ci";
import { civitripToken } from "../../api/requests";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);
  const nombre = useSelector((state) => state.aplicationConfig.userConfig.userData.nombre);
  const rol = useSelector((state) => state.aplicationConfig.userConfig.userData.rol);
  const pageActive = useSelector((state) => state.aplicationConfig.pageActive);
  const cartQuantity = useSelector((state) => state.aplicationConfig.cart.quantity);
  const [miniCart, setMiniCart] = useState(true);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [iframeHeight, setIframeHeight] = useState("600px");

  //Modal control
  const [openModal, setOpenModal] = React.useState(false);
  const handleClose = () => setOpenModal(false);

  const navigateAuthPanel = (section) => {
    if (rol === "cliente") {
      dispatch(setAdmSectionActive(section));
      navigate("/clientes");
    } else if (rol === "proveedor") {
      dispatch(setAdmSectionActive(section));
      dispatch(setAdmExperienceSelected(null));
      navigate("/proveedores");
    } else {
      console.log("Rol error");
    }
  };

  const logout = () => {
    dispatch(setUserConfig({ logged: false, userData: {} }));
    navigate("/login");
  };

  const MenuDesktop = () => {
    return (
      <div className="menuDesktop">
        {!logged && (
          <>
            <div className="menuItemDesktop" onClick={() => navigate("/proveedores")}>
              <FiUserPlus size={25} /> <div>Hazte proveedor</div>
            </div>

            <div className="menuItemDesktop" onClick={() => navigate("/login")}>
              <FiUser size={25} /> <div>Login</div>
            </div>
          </>
        )}
        {logged && (
          <>
            {rol === "proveedor" && (
              <>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("dashboard-provider")}>
                  <GoDeviceDesktop size={25} /> <div>Escritorio</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("bookings-provider")}>
                  <GoCalendar size={25} /> <div>Reservas</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("sales-provider")}>
                  <GoInbox size={25} /> <div>Ventas</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("experiences-provider")}>
                  <GoMilestone size={25} /> <div>Experiencias</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("bussiness-provider")}>
                  <GoOrganization size={25} /> <div>Empresa</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("profile")}>
                  <GoPerson size={25} />
                  {<div>{nombre}</div>}
                </div>
              </>
            )}
            {rol === "cliente" && (
              <>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("vouchers-client")}>
                  <GoMilestone size={25} /> <div>Reservas</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("billing-client")}>
                  <GoCreditCard size={25} /> <div>Facturación</div>
                </div>
                <div className="menuItemDesktop" onClick={() => navigateAuthPanel("profile")}>
                  <GoPerson size={25} />
                  {<div>{nombre}</div>}
                </div>
              </>
            )}
            <div className="menuItemDesktop" onClick={() => navigate("/wishlists")}>
              <FiHeart size={25} /> <div>Favoritos</div>
            </div>
            <div className="menuItemDesktop" onClick={handleModal}>
              <FiShoppingCart size={25} /> <div className="cartCounter">{cartQuantity}</div> <div>Carrito</div>
            </div>
            <div className="menuItemDesktop" onClick={() => logout()}>
              <GoSignOut size={25} color={"darkred"} /> <div style={{ color: "darkred" }}>Salir</div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleMenuMobile = () => {
    setShowMenuMobile(!showMenuMobile);
  };

  //Modal components

  const handleModal = () => {
    console.log("handleModal");
    setOpenModal(!openModal);
  };

  const ContentIframe = () => {
    const urlBase = "https://dev.crea-ticket.com/civitrip/";
    const apiToken = civitripToken;
    const src = urlBase + apiToken + "/cart";
    //const src = "http://localhost:3002/civitrip/mtefbovyxnBmwmfFsgEleFAfEtIlFrNrlrXNgTLeGMQDywIrdMcIRdnVWvHbnUTZMqlsJw/cart";
    return <iframe className="embebbedIframe" src={src} title="Carrito" height={iframeHeight}></iframe>;
  };

  const MenuMobile = () => {
    return (
      <div className="menu menuMobile">
        <FiMenu style={{ fontSize: "30px", padding: "30px" }} onClick={handleMenuMobile} />
        {showMenuMobile === true && (
          <div className="menuMobileContainer">
            <div className="menuItem link" onClick={() => navigate("/wishlists")}>
              <img src={iconFavorite} alt="proveedor" />
              <div>Favoritos</div>
            </div>
            <div className="menuItem link" onClick={handleModal}>
              <img src={iconCart} alt="carrito" />

              <div>Carrito</div>
            </div>
            <div className="menuItem link" onClick={() => navigate("/login")}>
              <GoPerson size={25} /> <div>Login</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  // =========== CALLBACK CART
  const cartUpdated = (value) => {
    console.log("Header > cartUpdated: ", value);
    console.log(JSON.parse(value).type, JSON.parse(value).value);
    if (JSON.parse(value).type === "cartItems") dispatch(setCartQuantity(parseInt(JSON.parse(value).value)));
  };

  useEffect(() => {
    const handleMessage = (e) => {
      if (/*e.origin === "http://localhost:3006" || e.origin === "http://localhost:3002" ||*/ e.origin === "https://dev.crea-ticket.com") {
        cartUpdated(e.data);
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //======================================

  return (
    <div className="header contentStretch">
      <div className="logo link">
        <div onClick={() => navigate("/")}>
          <img src={Logo} alt="logo" width="75%" />
        </div>
      </div>
      {pageActive === "experience" && (
        <div className="searchHeader">
          <SearchComponent experiencias={[]} showButton={false} />
        </div>
      )}
      <MenuDesktop />
      <MenuMobile />
      {openModal && <ModalComponent openModal={openModal} handleClose={handleClose} content={<ContentIframe />} />}
    </div>
  );
};

export default Header;
