import React from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setWishlist } from "./../../reducers/userConfigSlice";
//Components
import { HiOutlineLocationMarker, HiOutlineHeart, HiHeart } from "react-icons/hi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
//API
import { addWishlist, removeWishlist, getWishlists } from "./../../api/requests";
//Styles
import "./style.css";

export const Card = ({ e }) => {
  const idClient = useSelector((state) => state.aplicationConfig.userConfig.userData.id);
  const wishlist = useSelector((state) => state.aplicationConfig.wishlist);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getFavorites = async () => {
    await getWishlists({ id: idClient }).then((res) => {
      res && dispatch(setWishlist(res));
    });
  };

  const handleProduct = (e) => {
    navigate("/experience/" + e.pathName);
  };

  const blockStars = (value) => {
    const style = { "--rating-value": (value / 10) * 5 };
    return <div className="rating" style={style}></div>;
  };

  const getDefaultPrice = (e) => {
    let price = JSON.parse(e.prices).filter((f) => f.id === e.defaultPrice);
    if (price.length > 0) return parseInt(price[0].price) + " " + e.currency;
    else return 0 + " " + e.currency;
  };

  const addFavorite = async (idExperience) => {
    await addWishlist({ idClient: idClient, idExperience: idExperience }).then((res) => {
      getFavorites();
    });
  };

  const removeFavorite = async (idExperience) => {
    await removeWishlist({ idClient: idClient, idExperience: idExperience }).then((res) => {
      getFavorites();
    });
  };

  const handleFavorite = (id) => {
    if (wishlist.filter((f) => f.id === id).length > 0) removeFavorite(id);
    else addFavorite(id);
  };

  return (
    <div className="cardFeaturedHome">
      <div
        className="searcherCardBodyFeatured"
        style={{ backgroundImage: "url(" + e.photos.split(",")[0] + ")" }}
        onClick={() => handleProduct(e)}
      ></div>
      <div className="cardFeaturedHomeBody">
        <h3 className="cardFeaturedHomeTitle" onClick={() => handleProduct(e)}>
          {e.experienceName}
        </h3>
        <div className="cardFeaturedHomeSubTitle" onClick={() => handleProduct(e)}>
          {e.shortdesc}
        </div>

        <div className="rowCard">
          <HiOutlineLocationMarker className="svgStart" />
          <div>{e.ciudad}</div>
        </div>
        <div className="rowCard">
          <div className="blockStarts">
            {blockStars(9)} <span className="reviews">(1000 opiniones)</span>
          </div>
          {wishlist.filter((f) => f.id === e.id).length > 0 ? (
            <HiHeart className={"svgEnd link favorite"} onClick={() => handleFavorite(e.id)} />
          ) : (
            <HiOutlineHeart className={"svgEnd link"} onClick={() => handleFavorite(e.id)} />
          )}
        </div>
        <hr className="cardSeparator" />
        <div className="rowCard">
          <FaRegMoneyBillAlt className="svgStart" />
          <label style={{ fontSize: "0.95rem", paddingRight: "5px" }}> Desde {getDefaultPrice(e)} </label>
          <label style={{ color: "var(--link-color)" }}> por persona</label>
        </div>
      </div>
    </div>
  );
};

export default Card;
