import { useNavigate } from "react-router-dom";
import { FcOnlineSupport } from "react-icons/fc";
import "./style.css";
import visa from "./../../assets/icons/visa.svg";
import amex from "./../../assets/icons/amex.svg";
import diners from "./../../assets/icons/diners.svg";
import maestro from "./../../assets/icons/maestro.svg";
import mc from "./../../assets/icons/mc.svg";
import paypal from "./../../assets/icons/paypal.svg";
import rrss from "./../../assets/icons/rrss.png";
import Logo from "./../../assets/logos/logo.png";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="rowContent">
      <div className="footer ">
        <div className="contentStretch">
          <div className="footerRow">
            <div className="">
              <h2>EMPRESA</h2>
              <p>Nuestra empresa</p>
              <p>Blog</p>
              <p>Contactar</p>
              <p>Mapa del sitio</p>
              <p>Tarjetas Regalo</p>
              <p>Información conforme a la Ley de Servicios Digitales</p>
            </div>
            <div className="">
              <h2>TRABAJA CON NOSOTROS</h2>
              <p onClick={() => navigate("/proveedores")}>Trabaja con nosotros como proveedor de servicios</p>
              <p>Afiliado</p>
              <p>Creador de contenidos</p>
              <p>Agencias de viaje</p>
              <p>Alojamientos</p>
            </div>
            <div className="">
              <h2>FORMAS DE PAGO</h2>
              <div className="footerRowPayments">
                <img src={visa} alt="logo_visa" />
                <img src={amex} alt="logo_amex" />
                <img src={diners} alt="logo_diners" />
                <img src={maestro} alt="logo_maestro" />
                <img src={mc} alt="logo_mc" />
                <img src={paypal} alt="logo_paypal" />
              </div>
            </div>
          </div>

          <div className="footerRow">
            <div>
              <h2>AYUDA</h2>
              <div>
                <div className="">
                  <p>Atención al cliente</p>
                </div>
              </div>
            </div>

            <div>
              <div className="text-end">
                <h2>SÍGUENOS</h2>
                <p>
                  <img src={rrss} className="footer_rrss" alt="logo_rrss" />
                </p>
              </div>
              <div className="text-legal">
                <p>
                  Condiciones Generales · <a href="/aviso-legal">Aviso legal</a> · <a href="/politica-privacidad">Política de privacidad</a> ·{" "}
                  <a href="/cookies">Cookies</a> · Declaracción accesibilidad
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contentStretch">
        <div className="footerTwo">
          <div>
            <a href="/">
              <img src={Logo} width="150px" alt="logo_civitrip" />
            </a>
          </div>
          <div className="copyright">
            Copyright © 2024 Civitrip.com<sup>TM</sup>. Todos los derechos reservados.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
