import { Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import Logo from "./logo.png";

export const DownloadPDF = ({ data }) => {
  const dataDemo = {
    username: "Juan Pérez",
    bookingDate: "31 de Agosto de 2024",
    bookingName: "MARRAKECH: PUESTA DEL SOL EN EL DESIERTO",
    bookingUsers: "2 adultos",
    bookingTime: "5 horas",
    bookingPrice: "46,42 €",
    bookingCode: "GYWFFISO22",
    bookingPin: "52254782",
    bookingImage: "https://preyash2047.github.io/assets/img/no-preview-available.png",
  };

  const htmlContent = `
    <style>
        h3 {
            font-size: 1rem;
            margin: auto 0px;
            font-weight: normal; 
        }

        .container {
            max-width: 600px;
            margin: 20px auto;
            background: #fff;
            overflow: hidden;
            line-height: 1.6;
            color: #2f4f9e;
            font-size: 0.8rem;
        }

        .header {
            margin-top: 15px;
            background: #283170;
            color: #fff;
            text-align: left;
            padding: 80px 35px 40px 35px;
            border-radius: 20px;
            font-weight: bold;
            font-size: 3rem;
        }

        .header-logo {
            width: 35%;
            background-color: #f0f0f0;
            padding: 20px 20px 20px 17px;
            border-radius: 5px;
            margin-top: -90px;
            position: absolute;
        }

        span {
            color: #e964a1;
        }

        img {
            width: 100%;
        }

        p {
            margin: -25px 0px;
        }

        .separator {
            background-color: #e964a1;
            width: 100%;
            height: 2px;
        }

        .separator2 {
            background-color: #2f4f9e;
            width: 100%;
            height: 2px;
            margin: 0px 5px;
        }

        .booking {
            display: flex;
            justify-content: space-between;
        }

        .booking-info-p {
            margin: 0px 0px;
        }

        .booking-image {
            width: 40%;
        }

        .booking-image img {
            width: 100%;
        }

        .booking-codes {
            margin-top: 25px;
            display: flex;
            gap: 15px;
        }

        .booking-codes div {
            width: 33%;
        }

        .booking-codes label {
            color: #e964a1;
            font-weight: bold; 
            font-size: 0.8rem;
        }

        .booking-code {
            background: #2f4f9e;
            color: #fff;
            padding: 5px 10px;
            font-weight: bold;
            font-size: 1.5rem;
            border-radius: 10px;
        }

        .content {
            padding: 20px;
        }

        .footer {
            text-align: left;
            font-size: 0.6rem;
            padding: 10px;
        }

        .footer-logo" {
            width: 200px;
        }

        a {
            color: #e964a1;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    </style>

  
    <div class="container">
      <div class="header">
        <div class="header-logo"><img src=${Logo} alt="civitrip" /></div>
        <div>COMIENZA</div>
        <div>LA <span>EXPERIENCIA</span></div>
      </div>

      <div class="content">
        <h3>Muchas gracias por tu reserva, <span id="username">${dataDemo.username}</span></h3>
        <div class="separator"></div>

        <div>Estos son los datos de tu reserva:</div>
        <div class="booking">
          <div class="booking-info">
            <div class="booking-info-p"><span id="bookingDate">${dataDemo.bookingDate}</span></div>
            <div class="booking-info-p"><b id="bookingName">${dataDemo.bookingName}</b></div>
            <div class="booking-info-p"><span id="bookingUsers"></span> · <span id="bookingTime">${dataDemo.bookingUsers}</span></div>
            <div class="booking-info-p"><b id="bookingPrice">${dataDemo.bookingPrice}</b></div>
            <div class="booking-info-p"><br /><br /><a>Ver información de la actividad</a></div>
          </div>
          <div class="booking-image"><img id="bookingImage" src="${dataDemo.bookingImage}" alt="civitrip" /></div>
        </div>

        <div class="booking-codes">
          <div>
            <label>CÓDIGO DE RESERVA</label>
            <div id="bookingCode" class="booking-code">${dataDemo.bookingCode}</div>
          </div>
          <div>
            <label>CÓDIGO PIN</label>
            <div id="bookingPin" class="booking-code">${dataDemo.bookingPin}</div>
          </div>
        </div>
      </div>

      <br />
      <div class="separator2"></div>
      <div class="footer">
        <div class="footer-logo"><img  src="${Logo}" alt="civitrip" /></div>
        <div>Para mas información consulta nuestra <a href="#">política de privacidad</a></div>
      </div>
    </div>
  
`;

  const MyDocument = ({ data }) => (
    <Document>
      <Page size="A4">
        <Html>{htmlContent}</Html>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink
      document={<MyDocument data={data} />}
      fileName="ticket-reserva.pdf"
      style={{
        textDecoration: "none",
        color: "#e964a1",
        padding: "10px 15px",
        cursor: "pointer",
      }}
    >
      {({ loading }) => (loading ? "Generando documento..." : "Descargar")}
    </PDFDownloadLink>
  );
};
