import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//Datatable
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//Api
import { getClientCart } from "../../../../api/requests";
//Components
import { DownloadPDF } from "../../../../components/downloadPDF";

export const Reservas = () => {
  const userId = /*useSelector((state) => state.aplicationConfig.userConfig.userData.id)*/ 17;
  const [data, setData] = useState([]);
  const [tickets, setTickets] = useState([]);

  const getData = async () => {
    try {
      const res = await getClientCart(userId);
      if (res) {
        console.log(res);
        setData(res);
      } else {
        console.log("Error.");
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
      setData([]);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extractTickets = () => {
    let tickets = [];
    if (data.length > 0) {
      data.map((channel) => {
        if (channel.collaboratorTickets && channel.collaboratorTickets.length > 0) {
          channel.collaboratorTickets.map((ticket) =>
            tickets.push({ id: ticket.id, name: ticket.businessUnitName, date: ticket.datebook, image: ticket.urlImage })
          );
          setTickets(tickets);
        }
      });
    }
  };

  useEffect(() => {
    data.length > 0 && extractTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h2>Reservas</h2>
      <div className="section" style={{ display: "inline-flex", width: "97%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Destino</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell className="text-center">Ticket</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.length > 0 &&
                tickets.map((row) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <img src={row.image} width="50px" alt={row.name} />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell className="text-center">
                      <DownloadPDF data={row} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
